import React from 'react'
import get from 'lodash.get'
import ImageBlock from './ImageBlock'
import KeywordsBlock from './KeywordsBlock'
import './style.scss'

const PageBlock = ({ block }) => {
  const type = get(block, 'type', null)
  const h2 = get(block, 'primary.titre_niveau_1[0].text', null)
  const h3 = get(block, 'primary.titre_niveau_2[0].text', null)
  const h4 = get(block, 'primary.titre_niveau_3[0].text', null)
  const fields = get(block, 'fields', null)

  return (
    <div key={block.id} className={`page--block page--block__${type}`}>
      <div className="container">
        {type === 'text_structure' && (
          <div>
            {h2 && <h2>{h2}</h2>}
            {h3 && <h3 className="ft-subtitle">{h3}</h3>}
            {h4 && <h4 className="ft-subtitle">{h4}</h4>}

            <div className="paragraphs">
              {fields &&
                fields.map(p => {
                  const text = get(p, 'paragraph[0].text', null)

                  return !!text ? (
                    <p className="ft-paragraph" key={text}>
                      {text}
                    </p>
                  ) : null
                })}
            </div>
          </div>
        )}
      </div>

      {type === 'nuage_mots_cle' && <KeywordsBlock keywords={fields} />}

      {type === 'image' && (
        <div className="container">
          <ImageBlock block={block} />
        </div>
      )}
    </div>
  )
}

export default PageBlock
