import React from 'react'
import get from 'lodash.get'

const ImageBlock = ({ block }) => {
  const url = get(block, 'primary.image.url', null)
  const alt = get(block, 'primary.image.alt', '')

  return (
    <div className="image">
      <img src={url} alt={alt} />
    </div>
  )
}

export default ImageBlock
