import React from 'react'
import './style.scss'

const KeywordsBlock = ({ keywords }) => {
  return !!keywords ? (
    <div className="ft-subtitle keywords">
      {keywords.map(({ keyword }) => (
        <span key={keyword}>{keyword}</span>
      ))}
    </div>
  ) : null
}

export default KeywordsBlock
