import React from 'react'
import PageBlock from '../components/PageBlock'

const Page = ({ blocks }) => (
  <>
    {blocks.map(block => (
      <PageBlock key={block.id} block={block} />
    ))}
  </>
)

export default Page
